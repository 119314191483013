import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";
import copyIcon from "../img/icons8-copy-48.png";

const ProjectItem = ({
  project,
  index,
  copiedText,
  setCopiedText,
  cat,
  currentTab,
  setCurrentTab,
}) => {
  return (
    <div className="logo-inside">
      <a href={project?.link} target="_blank" rel="noreferrer">
        <div className="logo-img">
          <img className="img-responsive" src={project?.icon.url} alt="" />
        </div>
      </a>
      <div className="content">
        <p>{project?.logoTitle}</p>
        <div className="tooltip">
          <img
            src={copyIcon}
            alt=""
            onClick={() => [
              setCurrentTab(cat),
              setCopiedText(index),
              navigator.clipboard.writeText(project?.link),
            ]}
          />
          <span
            className="tooltiptext"
            style={{
              backgroundColor:
                currentTab === cat && copiedText === "Copied"
                  ? "#04d523"
                  : "black",
            }}
          >
            {currentTab === cat && copiedText === "Copied"
              ? "Copied"
              : "Copy Link"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
