import "./App.css";
import React, { useEffect, useState } from "react";
import { request } from "graphql-request";
import ProjectItem from "./components/projectItem";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import BackToTopButton from "./components/BackToTopButton";

function App() {
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [copiedText, setCopiedText] = useState("Copy Link");
  const [currentTab, setCurrentTab] = useState("Qode Corp");
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  useEffect(() => {
    const query = `
    {
      menus {
        title
        records {
          ... on Record {
            link
          logoTitle
          position
          icon {
            url
            width
            height
          }
          }
        }
       
      }
    }
  `;
    request(
      "https://api-ap-south-1.hygraph.com/v2/cl8tzshgt512y01t89kd0anxu/master",
      query
    ).then((result) => {
      setData(result.menus);
    });
  }, []);

  // Function to open sidebar
  const openSidebar = (val) => {
    setSidebarIsOpen(val);
  };

  // Get the title of active tab
  const activeTitle = data && data[activeTab] ? data[activeTab].title : "";

  return (
    <div className="main-wrapper">
      {/* Header */}
      <Header hamburgerClick={openSidebar} />
      {/* Sidebar */}
      <Sidebar
        linkData={data}
        isSidebarOpen={sidebarIsOpen}
        changeSidebarState={setSidebarIsOpen}
        changeActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <div className="container categories_section">
        <div className="active_tab_title">
          <h1 className="title">{activeTitle}</h1>
        </div>
        <div className="tab">
          <div className={`categories active`}>
            <div className="logo-section">
              {data &&
                data[activeTab].records
                  .sort((a, b) => b.position - a.position)
                  .map((currElem, id) => (
                    <ProjectItem
                      key={id}
                      project={currElem}
                      index={id}
                      copiedText={copiedText === id ? "Copied" : "Copy Link"}
                      setCopiedText={setCopiedText}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer
        linkData={data}
        changeActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <BackToTopButton />
    </div>
  );
}

export default App;
