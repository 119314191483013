import CloseIcon from "./../img/close.svg";
import React from "react";
import styles from "../styles/components/Sidebar.module.css";

export default function Sidebar({
  linkData,
  isSidebarOpen,
  changeSidebarState,
  changeActiveTab,
  activeTab,
}) {
  return (
    <div className={`${styles.sidebar} ${isSidebarOpen && styles.open}`}>
      <div
        className={styles.sidebar_overlay}
        onClick={() => changeSidebarState(false)}
      ></div>
      <div className={styles.sidebar_content}>
        {/* Close icon */}
        <div
          className={styles.close_icon}
          onClick={() => changeSidebarState(false)}
        >
          <img src={CloseIcon} alt="" />
        </div>
        {/* Category Links */}
        <ul className={styles.sidebar_links}>
          {linkData?.map((link, index) => (
            <li
              className={`${styles.sidebar_link} ${
                activeTab === index && styles.active
              }`}
              key={"link" + index}
              onClick={() => {
                changeSidebarState(false);
                changeActiveTab(index);
              }}
            >
              {link.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
